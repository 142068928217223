import { Group, Text } from "@mantine/core"
import { ThumbUp } from "tabler-icons-react"

interface Props {
  text: string
}

export function ThumbsUpLabel({ text }: Props) {
  return (
    <Group position="left" noWrap style={{ width: "75%" }}>
      <ThumbUp size={36} style={{ flexGrow: 0, flexShrink: 0 }} />
      <Text>{text}</Text>
    </Group>
  )
}
