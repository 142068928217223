import axios from "axios"

export const APP_NAME = "GK Web"
export const HOMEPAGE_URL = "https://example.com"
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL
axios.defaults.baseURL = BASE_API_URL

export const COLORS = {
  red: "#AE0D13",
  gray: "#EFEFEF",
}

export const LARGE_SCREEN_WIDTH = "(min-width: 950px)"
