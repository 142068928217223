import { Group, Image, Text } from "@mantine/core"
import { GetStarted, IMarketingPage } from "features/marketing-pages"
import { IStrapiEntity } from "services/strapi"
import { COLORS } from "settings"

interface Props {
  data?: IStrapiEntity<IMarketingPage>
  contactEmail?: string
  contactPhone?: string
  contactName?: string
  onSubmit?: (name: string, phone: string, email: string) => void
}

export function MarketingPageHeader({
  data,
  contactEmail,
  contactPhone,
  contactName,
  onSubmit,
}: Props) {
  if (!data) return null

  return (
    <Group
      position="apart"
      noWrap
      pl={32}
      pr={32}
      style={{ width: "100%", borderBottom: "1px solid black" }}
      pt={16}
      pb={16}
    >
      <Image
        fit="contain"
        src={data?.attributes.logoUrl}
        style={{ maxHeight: 200, maxWidth: 200 }}
      />
      <Group position="center">
        <Group spacing={0}>
          <Text color={COLORS.red} mr={4}>
            Call:
          </Text>
          <Text underline>
            <a href={`tel:+1${data?.attributes.phoneNumber}`}>
              {data?.attributes.phoneNumber}
            </a>
          </Text>
        </Group>
        {/* <Button size="lg" variant="outline">
            For Agents
          </Button> */}
        <GetStarted
          data={data}
          contactEmail={contactEmail}
          contactPhone={contactPhone}
          contactName={contactName}
          onSubmit={(name: string, phone: string, email: string) => {
            if (onSubmit) onSubmit(name, phone, email)
          }}
        />
      </Group>
    </Group>
  )
}
