import {ModelRoutes} from "services/strapi";
import {IFlatUser} from "features/users";
import qs from "qs";
import {IStrapiResponse} from "services/strapi";
import {strapi} from "lib/strapi-api";
import axios from "axios";

class UserApiSingleton {

    serialize = {
        update(values: any) {
            const formData = new FormData();
            const {avatar, id, ...data} = values;
            if (avatar && avatar.length === 1) {
                formData.append(`files.avatar`, avatar[0], avatar[0].name);
            }
            formData.append('data', JSON.stringify(data));
            return {
                id: id,
                values: formData
            }
        }
    }

    async getUsers(rawQuery: any) {
        const query = qs.stringify(rawQuery);
        try {
            const res = await strapi.get(`/${ModelRoutes.Users}?${query}`);
            return res.data as IStrapiResponse<IFlatUser[]>;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.error('Could not get users: ', e.message);
            }
            return undefined;
        }
    }

    async getUserAvatars(userIds: number[]) {
        try {
            const res = await strapi.post(`/${ModelRoutes.Users}/avatars`, {userIds});
            return res.data as any;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.error('Could not get user: ', e.message);
            }
            return undefined;
        }
    }

    async getUser(userId: number) {
        const rawQuery = {
            populate: "*"
        }
        const query = qs.stringify(rawQuery);
        try {
            const res = await strapi.get(`/${ModelRoutes.Users}/${userId}?${query}`);
            return res.data as IFlatUser;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.error('Could not get user: ', e.message);
            }
            return undefined;
        }
    }

    async getUserRoleInvitationPermissions(roleName: string) {
        const query = qs.stringify({roleName});
        try {
            const res = await strapi.get(`/${ModelRoutes.Users}/invite/role-permissions?${query}`);
            return res.data as string[];
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.error('Could not get invitation role permissions: ', e.message);
            }
            return undefined;
        }
    }

    async inviteUser(values: any) {
        try {
            const res = await strapi.post(`/${ModelRoutes.Users}/invite`, values);
            return res.data;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.error('Error inviting user', e.response?.data);
                return {errors: e.response?.data};
            }
            return undefined;
        }
    }

    async acceptInvitation(password: string, invitationToken: string) {
        try {
            const res = await strapi.post(`/${ModelRoutes.Users}/invite/accept`, {
                password,
                invitationToken
            });
            return res.data;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.data) {
                    return e.response.data?.non_field_errors?.[0]
                }
            }
            console.error('Could not accept invitation');
            return undefined;
        }
    }

    async createUser(values: any) {
        try {
            const res = await strapi.post(`/${ModelRoutes.Users}`, {data: values});
            return res;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.error('Error creating user', e.response?.data);
                return {errors: e.response?.data};
            }
        }
    }

    async updateUser(userId: number, values: any, rawQuery:any) {
        try {
            const query = qs.stringify(rawQuery);
            const res = await strapi.put(`/${ModelRoutes.Users}/${userId}?${query}`, values);
            return res.data as IFlatUser;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.error('Error updating user', e.message);
            }
            return undefined;
        }
    }

    async getEmailFromInvitationToken(invitationToken: string) {
        try {
            const res = await strapi.get(`/${ModelRoutes.Users}/invite/email`, {params: {invitationToken}});
            return res.data;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.data) {
                    return e.response.data?.non_field_errors?.[0]
                }
            }
            console.error('Could not get email from invitationToken');
            return undefined;
        }
    }
}

export const userApi = new UserApiSingleton()


