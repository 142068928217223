import {
  Button,
  Grid,
  Group,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core"
import { notEmpty, useForm } from "@mantine/form"
import { StateSelect } from "components/common/state-select"
import {
  ILeadForm,
  IMarketingPage,
  marketingPageApi,
} from "features/marketing-pages"
import { useState } from "react"
import { IAddressComponent } from "services/google"
import { IStrapiEntity } from "services/strapi"

interface Props {
  data?: IStrapiEntity<IMarketingPage>
  onSubmit?: (name: string, phone: string, email: string) => void
  contactEmail?: string
  contactPhone?: string
  contactName?: string
  addressComponents?: IAddressComponent[] | null
}

export function LeadForm({
  data,
  onSubmit,
  addressComponents,
  contactEmail,
  contactPhone,
  contactName,
}: Props) {
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const getStreetAddressInitial = () => {
    if (addressComponents) {
      const street = addressComponents?.find((c) =>
        c.types.includes("route")
      )?.long_name
      const number = addressComponents?.find((c) =>
        c.types.includes("street_number")
      )?.long_name
      return `${number} ${street}`
    } else return ""
  }

  const form = useForm<ILeadForm>({
    initialValues: {
      market: data?.attributes.slug || "",
      streetAddress: getStreetAddressInitial(),
      city:
        addressComponents?.find((c) => c.types.includes("locality"))
          ?.long_name || "",
      state:
        addressComponents?.find((c) =>
          c.types.includes("administrative_area_level_1")
        )?.short_name || "",
      zip:
        addressComponents?.find((c) => c.types.includes("postal_code"))
          ?.short_name || "",
      contactName: contactName || "",
      contactEmail: contactEmail || "",
      contactPhoneNumber: contactPhone || "",
      mortgageBalance: "",
    },
    validate: {
      market: notEmpty(),
      streetAddress: notEmpty(),
      city: notEmpty(),
      state: notEmpty(),
      zip: notEmpty(),
      contactName: notEmpty(),
      contactEmail: notEmpty(),
    },
  })

  async function _onSubmit(values: typeof form.values) {
    setLoading(true)
    await marketingPageApi.submitLeadForm(values)
    setLoading(false)
    setSubmitted(true)
  }

  if (!data) return null

  return (
    <>
      {!submitted && (
        <form onSubmit={form.onSubmit(_onSubmit)}>
          <Grid>
            <Grid.Col sm={4}>
              <TextInput
                placeholder="Street Address"
                {...form.getInputProps("streetAddress")}
              />
            </Grid.Col>
            <Grid.Col sm={4}>
              <TextInput placeholder="City" {...form.getInputProps("city")} />
            </Grid.Col>
            <Grid.Col sm={2}>
              <StateSelect
                placeholder="State"
                {...form.getInputProps("state")}
              />
            </Grid.Col>
            <Grid.Col sm={2}>
              <TextInput placeholder="Zip" {...form.getInputProps("zip")} />
            </Grid.Col>
            <Grid.Col sm={4}>
              <TextInput
                placeholder="Contact Name"
                {...form.getInputProps("contactName")}
              />
            </Grid.Col>
            <Grid.Col sm={4}>
              <TextInput
                placeholder="Contact Email"
                {...form.getInputProps("contactEmail")}
              />
            </Grid.Col>
            <Grid.Col sm={4}>
              <TextInput
                placeholder="Contact Phone"
                {...form.getInputProps("contactPhoneNumber")}
              />
            </Grid.Col>
            <Grid.Col sm={4}>
              <TextInput
                placeholder="Mortgage Balance (if applicable)"
                {...form.getInputProps("mortgageBalance")}
              />
            </Grid.Col>
            <Grid.Col sm={12}>
              <Group position="left" mt="md">
                <Button type="submit" loading={loading} size="md">
                  Submit
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        </form>
      )}
      {submitted && (
        <Stack>
          <Title size={24}>Thank you!</Title>
          <Text size={"lg"}>
            Your request has been successfully submitted. We will reach out by
            email within 24 hours.
          </Text>
          <Group position="right">
            <Button
              onClick={() => {
                if (onSubmit)
                  onSubmit(
                    form.values.contactName,
                    form.values.contactPhoneNumber,
                    form.values.contactEmail
                  )
              }}
              size="lg"
            >
              Close
            </Button>
          </Group>
        </Stack>
      )}
    </>
  )
}
