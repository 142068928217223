import {StrapiAuthHooks} from "services/strapi";
import {authApi} from "features/auth";

const meQuery = {populate: ['role', 'userDatum', 'userDatum.avatar']};

class AuthHooks extends StrapiAuthHooks {

}

export const authHooks = new AuthHooks(authApi, meQuery);
