import { ModelRoutes, ModelApiService } from "services/strapi"
import {
  IContactForm,
  ILeadForm,
  IMarketingPage,
} from "features/marketing-pages"
import axios from "axios"

class MarketingPageApiSingleton extends ModelApiService<IMarketingPage> {
  async submitLeadForm(values: ILeadForm): Promise<void> {
    try {
      await axios.post(
        `https://api.progress.harvestgkp.com/leads/conversion`,
        values
      )
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(`[submitLeadForm] Error`, e.message)
      }
    }
  }
  async submitContactForm(values: IContactForm): Promise<void> {
    try {
      await axios.post(
        `https://api.progress.harvestgkp.com/nurture/create`,
        values
      )
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(`[submitLeadForm] Error`, e.message)
      }
    }
  }
}

export const marketingPageApi = new MarketingPageApiSingleton(
  ModelRoutes.MarketingPages
)
