import { Stack, Text, Title } from "@mantine/core"
import { IFaq } from "features/faqs/types"
import { IMarketingPage } from "features/marketing-pages"
import { IStrapiEntity } from "services/strapi"
import { FaqDisplay } from "../faq-display"

interface Props {
  data?: IStrapiEntity<IFaq>[]
  marketingPage?: IStrapiEntity<IMarketingPage>
}

export function FaqsPreview({ data }: Props) {
  if (!data) return null

  return (
    <Stack>
      <Title align="center">Frequently asked questions</Title>
      {data?.slice(0, 3).map((f, i) => {
        return <FaqDisplay key={i} data={f} />
      })}
      <Text
        underline
        style={{ cursor: "pointer" }}
        size={24}
        ml={48}
        color="navy"
        onClick={() => {
          window.open(
            `${
              window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname
            }/faqs`,
            "_blank"
          )
        }}
      >
        See all FAQs
      </Text>
    </Stack>
  )
}
