import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
  Outlet,
} from "react-router-dom"
import { AppWrapper } from "layouts"
import {
  MarketingPage,
  /* Generated Feature Pages */
  Faqs,
  Test,
} from "pages"
import { RouteSegments } from "./segments"

import TagManager from "react-gtm-module"

const tagManagerArgs = {
  gtmId: "GTM-5JWZTF99",
}

TagManager.initialize(tagManagerArgs)

export function AppRoutes() {
  function renderAllRoutes() {
    return (
      <Route element={<AppWrapper />}>
        <Route path={`test`} element={<Test />} />

        {/* Generated React Router Pages */}

        <Route element={<Outlet />}>
          <Route path={`:slug/${RouteSegments.Faqs}`} element={<Faqs />} />
          <Route index path={`:slug`} element={<MarketingPage />} />
          <Route
            index
            path={`:/${RouteSegments.Wildcard}`}
            element={<MarketingPage />}
          />
        </Route>
      </Route>
    )
  }

  const router = createBrowserRouter(
    createRoutesFromElements(renderAllRoutes())
  )

  return <RouterProvider router={router} />
}

export default AppRoutes
