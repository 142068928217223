import { Group, Text } from "@mantine/core"

interface Props {
  leftText: string
  rightText: string | number | undefined
  type?: "text" | "percent" | "dollar"
  large?: boolean
  color?: "red" | "green" | "orange"
}

export function OptionLabel({
  leftText,
  rightText,
  type = "text",
  large,
  color,
}: Props) {
  return (
    <Group position="apart" style={{ width: "80%" }} noWrap>
      <Text align="left" size={"sm"}>
        {leftText}
      </Text>
      {type === "text" && (
        <Text
          align="right"
          size={large ? "xl" : "sm"}
          weight={600}
          color={color ? color : "gray"}
        >
          {rightText}
        </Text>
      )}
      {type === "percent" && (
        <Text
          align="right"
          size={"xl"}
          weight={600}
          color={color ? color : "black"}
        >
          {rightText}%
        </Text>
      )}
      {type === "dollar" && (
        <Text
          align="right"
          size={large ? 32 : "xl"}
          weight={600}
          color={color ? color : "black"}
        >
          ${rightText?.toLocaleString()}
        </Text>
      )}
    </Group>
  )
}
