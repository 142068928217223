import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import {
  MantineProvider,
  ColorSchemeProvider,
  ColorScheme
} from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { AppRoutes } from "routes";
import { QueryClientProvider } from "@tanstack/react-query";
import { ModalsProvider } from "@mantine/modals";
import { queryClient } from "./settings";
import { useColorScheme } from "@mantine/hooks";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function Entrypoint() {
  const preferredColorScheme = useColorScheme();
  const localStorageScheme = localStorage.getItem("colorScheme");
  const [colorScheme, setColorScheme] = useState<ColorScheme>(
    (localStorageScheme as ColorScheme) || preferredColorScheme
  );
  const toggleColorScheme = (value?: ColorScheme) => {
    localStorage.setItem(
      "colorScheme",
      value || (colorScheme === "dark" ? "light" : "dark")
    );
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  };

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        withGlobalStyles
        theme={{
          colorScheme: colorScheme,
          fontFamily: "Open Sans, sans-serif",
          headings: {
            fontFamily: "Rubik, sans-serif"
          },
          components: {
            Anchor: {
              styles: () => ({
                root: {
                  color: "#AE0D13"
                }
              })
            },
            Button: {
              styles: (theme, params) => ({
                root: {
                  backgroundColor: "#AE0D13",
                  "&:hover": {
                    backgroundColor:
                      params.variant === "filled" ? "#AE0D13" : "transparent"
                  }
                }
              })
            }
          },
          globalStyles: (/*theme*/) => ({
            body: {
              margin: 0,
              padding: 0,
              fontWeight: 500,
              WebkitFontSmoothing: "antialiased",
              MozOsxFontSmoothing: "grayscale",
              height: "100vh"
            }
          })
        }}
      >
        <NotificationsProvider>
          <QueryClientProvider client={queryClient}>
            <ModalsProvider>
              <AppRoutes />
            </ModalsProvider>
          </QueryClientProvider>
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

root.render(<Entrypoint />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
