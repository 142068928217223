import { Group, Image, Space, Stack, Text } from "@mantine/core";
import { IMarketingPage } from "features/marketing-pages";
import { IStrapiEntity } from "services/strapi";

interface Props {
  data?: IStrapiEntity<IMarketingPage>;
}

export function MarketingPageFooter({ data }: Props) {
  if (!data) return null;

  return (
    <Stack style={{ width: "100%", borderTop: "1px solid black" }}>
      <Group
        position="center"
        noWrap
        pl={64}
        mt={32}
      >
        <Stack
          align="center"
          justify="center"
        >
          <Stack
            align="center"
            justify="center"
          >
            <Image
              src={data?.attributes.logoUrl}
              style={{ maxWidth: 200, maxHeight: 100 }}
              fit="contain"
            />
          </Stack>
          <Text>{data.attributes.footerName}</Text>
          <Text>{data.attributes.footerTeam}</Text>
          <Text>{data.attributes.footerAddress}</Text>
          <Text>
            <a href={`mailto:${data.attributes.footerEmail}`}>
              {data.attributes.footerEmail}
            </a>
          </Text>
          <Text>
            <a href={`tel:+1${data?.attributes.footerPhoneNumber}`}>
              {data?.attributes.footerPhoneNumber}
            </a>
          </Text>
        </Stack>
      </Group>
      <Space h="xl" />
      <Space h="xl" />
      <Space h="xl" />
    </Stack>
  );
}
