import { Stack, Title } from "@mantine/core"
import { IFaq } from "features/faqs/types"
import { IStrapiEntity } from "services/strapi"
import { COLORS } from "settings"
import Markdown from "react-markdown"

interface Props {
  data?: IStrapiEntity<IFaq>
}

export function FaqDisplay({ data }: Props) {
  if (!data) return null

  return (
    <Stack
      style={{
        border: `1px solid ${COLORS.gray}`,
      }}
      p={32}
      pr={64}
      pl={64}
      spacing={0}
    >
      <Title size={24}>{data.attributes.title}</Title>
      <Markdown>{data.attributes.body}</Markdown>
    </Stack>
  )
}
