import { RouteSegments } from "./segments"
import { BaseRoutesResolver } from "utils"

export class RoutesResolver extends BaseRoutesResolver {
  constructor() {
    super("")
  }

  get dashboard() {
    return this.concatRoutes([RouteSegments.Dashboard])
  }

  get users() {
    console.log(this.concatRoutes([RouteSegments.Users]))
    return this.concatRoutes([RouteSegments.Users])
  }

  get forgotPassword() {
    return this.concatRoutes(
      [RouteSegments.Auth, RouteSegments.ForgotPassword],
      true
    )
  }
  get login() {
    return this.concatRoutes([RouteSegments.Auth, RouteSegments.Login], true)
  }

  /* Generated Routes Resolver */

                getFaqById(faqId:number){
                    return this.concatRoutes([this.faqs, faqId]);
                }
            

                get faqs(){
                    return this.concatRoutes([RouteSegments.Faqs], true);
                }
            
}
