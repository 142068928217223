import { Grid, Paper, Space, Stack, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IMarketingPage } from "features/marketing-pages"
import { IStrapiEntity } from "services/strapi"
import { COLORS, LARGE_SCREEN_WIDTH } from "settings"
import { Number1, Number2, Number3 } from "tabler-icons-react"

interface Props {
  data?: IStrapiEntity<IMarketingPage>
}

export function MarketingPageSeamlessHomeSelling({ data }: Props) {
  const largeScreen = useMediaQuery(LARGE_SCREEN_WIDTH)

  if (!data) return null

  return (
    <Paper
      style={{
        backgroundColor: COLORS.gray,
        borderRadius: 8,
      }}
      p={32}
      pb={48}
      pt={16}
    >
      <Stack m={16}>
        <Text weight={700} align="center" size={"xl"}>
          Seamless Home Selling: Easy as 1 2 3
        </Text>
        <Space h="xl" />
        {largeScreen && (
          <Grid>
            <Grid.Col sm={4}>
              <Stack align="center" justify="center">
                <Stack
                  align="center"
                  justify="center"
                  style={{
                    backgroundColor: COLORS.red,
                    borderRadius: "50%",
                    border: "1px solid black",
                  }}
                  p={32}
                >
                  <Number1 color="white" size={32} />
                </Stack>
              </Stack>
            </Grid.Col>
            <Grid.Col sm={4}>
              <Stack align="center" justify="center">
                <Stack
                  align="center"
                  justify="center"
                  style={{
                    backgroundColor: COLORS.red,
                    borderRadius: "50%",
                    border: "1px solid black",
                  }}
                  p={32}
                >
                  <Number2 color="white" size={32} />
                </Stack>
              </Stack>
            </Grid.Col>
            <Grid.Col sm={4}>
              <Stack align="center" justify="center">
                <Stack
                  align="center"
                  justify="center"
                  style={{
                    backgroundColor: COLORS.red,
                    borderRadius: "50%",
                    border: "1px solid black",
                  }}
                  p={32}
                >
                  <Number3 color="white" size={32} />
                </Stack>
              </Stack>
            </Grid.Col>
            <Grid.Col sm={4}>
              <Text color={COLORS.red} weight={600} align="center">
                Submit Property and Contact Info
              </Text>
            </Grid.Col>
            <Grid.Col sm={4}>
              <Text color={COLORS.red} weight={600} align="center">
                Review a Cash Offer and Listing Option in as Little as 24 Hours
              </Text>
            </Grid.Col>
            <Grid.Col sm={4}>
              <Text color={COLORS.red} weight={600} align="center">
                Decide Your Path and Complete the Journey
              </Text>
            </Grid.Col>
            <Grid.Col sm={4}>
              <Stack
                style={{
                  backgroundColor: COLORS.red,
                  borderRadius: 8,
                  border: "1px solid black",
                  height: 200,
                }}
                pt={32}
                pb={32}
                pr={16}
                pl={16}
                justify="center"
                align="center"
              >
                <Text align="center" color="white">
                  Start your journey by sharing essential details about your
                  property and how we can reach you.
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col sm={4}>
              <Stack
                style={{
                  backgroundColor: COLORS.red,
                  borderRadius: 8,
                  border: "1px solid black",
                  height: 200,
                }}
                pt={32}
                pb={32}
                pr={16}
                pl={16}
                justify="center"
                align="center"
              >
                <Text align="center" color="white">
                  In just 24 hours, our dedicated agent will present you with
                  two compelling options: a quick cash offer and a tailored
                  listing plan.
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col sm={4}>
              <Stack
                style={{
                  backgroundColor: COLORS.red,
                  borderRadius: 8,
                  border: "1px solid black",
                  height: 200,
                }}
                pt={32}
                pb={32}
                pr={16}
                pl={16}
                justify="center"
                align="center"
              >
                <Text align="center" color="white">
                  Choose the path that suits your goals - accept the cash offer
                  for a swift sale or embark on the listing journey for maximum
                  returns. We'll guide you every step of the way.
                </Text>
              </Stack>
            </Grid.Col>
          </Grid>
        )}
        {!largeScreen && (
          <Grid>
            <Grid.Col sm={12}>
              <Stack align="center" justify="center">
                <Stack
                  align="center"
                  justify="center"
                  style={{
                    backgroundColor: COLORS.red,
                    borderRadius: "50%",
                    border: "1px solid black",
                  }}
                  p={32}
                >
                  <Number1 color="white" size={32} />
                </Stack>
              </Stack>
            </Grid.Col>
            <Grid.Col sm={12}>
              <Text color={COLORS.red} weight={600} align="center">
                Submit Property and Contact Info
              </Text>
            </Grid.Col>
            <Grid.Col sm={12}>
              <Stack
                style={{
                  backgroundColor: COLORS.red,
                  borderRadius: 8,
                  border: "1px solid black",
                  height: 200,
                }}
                pt={32}
                pb={32}
                pr={16}
                pl={16}
                justify="center"
                align="center"
              >
                <Text align="center" color="white">
                  Start your journey by sharing essential details about your
                  property and how we can reach you.
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col sm={4}>
              <Stack align="center" justify="center">
                <Stack
                  align="center"
                  justify="center"
                  style={{
                    backgroundColor: COLORS.red,
                    borderRadius: "50%",
                    border: "1px solid black",
                  }}
                  p={32}
                >
                  <Number2 color="white" size={32} />
                </Stack>
              </Stack>
            </Grid.Col>
            <Grid.Col sm={12}>
              <Text color={COLORS.red} weight={600} align="center">
                Review a Cash Offer and Listing Option in as Little as 24 Hours
              </Text>
            </Grid.Col>

            <Grid.Col sm={12}>
              <Stack
                style={{
                  backgroundColor: COLORS.red,
                  borderRadius: 8,
                  border: "1px solid black",
                  height: 200,
                }}
                pt={32}
                pb={32}
                pr={16}
                pl={16}
                justify="center"
                align="center"
              >
                <Text align="center" color="white">
                  In just 24 hours, our dedicated agent will present you with
                  two compelling options: a quick cash offer and a tailored
                  listing plan.
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col sm={12}>
              <Stack align="center" justify="center">
                <Stack
                  align="center"
                  justify="center"
                  style={{
                    backgroundColor: COLORS.red,
                    borderRadius: "50%",
                    border: "1px solid black",
                  }}
                  p={32}
                >
                  <Number3 color="white" size={32} />
                </Stack>
              </Stack>
            </Grid.Col>

            <Grid.Col sm={12}>
              <Text color={COLORS.red} weight={600} align="center">
                Decide Your Path and Complete the Journey
              </Text>
            </Grid.Col>

            <Grid.Col sm={12}>
              <Stack
                style={{
                  backgroundColor: COLORS.red,
                  borderRadius: 8,
                  border: "1px solid black",
                  height: 200,
                }}
                pt={32}
                pb={32}
                pr={16}
                pl={16}
                justify="center"
                align="center"
              >
                <Text align="center" color="white">
                  Choose the path that suits your goals - accept the cash offer
                  for a swift sale or embark on the listing journey for maximum
                  returns. We'll guide you every step of the way.
                </Text>
              </Stack>
            </Grid.Col>
          </Grid>
        )}
      </Stack>
    </Paper>
  )
}
