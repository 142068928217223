import { Divider, Grid, Group, Stack, Text } from "@mantine/core"
import { IMarketingPage } from "features/marketing-pages"
import { IStrapiEntity } from "services/strapi"
import { ThumbsUpLabel } from "./thumbs-up-label"
import { OptionLabel } from "./option-label"

interface Props {
  data?: IStrapiEntity<IMarketingPage>
}

export function MarketingPageSellingOptions({ data }: Props) {
  if (!data) return null

  return (
    <Group
      style={{
        border: "1px solid black",
        width: "100%",
        borderRadius: 8,
      }}
      noWrap
    >
      <Grid>
        <Grid.Col sm={6}>
          <Stack
            align="center"
            style={{
              borderRight: "1px solid black",
              height: "100%",
            }}
            p={16}
          >
            <Text weight={"bold"} size={"xl"}>
              CASH OFFER
            </Text>
            <Text size={"lg"} style={{ width: "90%" }}>
              Simplify your sale for a small service fee. We'll get you an offer
              from our trusted cash buyer, and deliver the most convenient
              selling experience possible.
            </Text>

            <Stack
              style={{ width: "100%", height: "100%" }}
              align="center"
              justify="center"
            >
              <ThumbsUpLabel text="Get a cash offer" />
              <ThumbsUpLabel text="Skip repairs and showings" />
              <ThumbsUpLabel text="Close on your own timeline" />
            </Stack>

            <Divider size="md" style={{ width: "90%" }} />

            <OptionLabel
              leftText="Example Sale Price"
              rightText={data?.attributes.cashSalePrice}
              type="dollar"
              color="orange"
            />

            <OptionLabel
              leftText="Selling Commission (Service Fee)"
              rightText={data?.attributes.cashCommission}
              type="percent"
            />

            <OptionLabel
              leftText="Tax and Title Fees"
              rightText={data?.attributes.cashTaxTitleFee}
              type="percent"
            />

            <OptionLabel leftText="Selling As-Is" rightText={0} large />

            <OptionLabel
              leftText="Est. Net Proceeds"
              rightText={data?.attributes.cashNetProceeds}
              type="dollar"
              large
              color="green"
            />
          </Stack>
        </Grid.Col>
        <Grid.Col sm={6}>
          <Stack align="center" style={{ height: "100%" }} p={16}>
            <Text weight={"bold"} size={"xl"}>
              SELLING TRADITIONALLY
            </Text>
            <Text size={"lg"} style={{ width: "90%" }}>
              In effort to maximize your net proceeds and sell for the highest
              price, utilize our experienced team and market your home to the
              public via the MLS and real estate websites.
            </Text>

            <Stack
              style={{
                width: "100%",
                height: "100%",
              }}
              align="center"
              justify="center"
            >
              <ThumbsUpLabel text="Maximize your sales price" />
              <ThumbsUpLabel text="Negotiate customized sales terms" />
              <ThumbsUpLabel text="Get expert guidance from the top realtors in your state" />
            </Stack>

            <Divider size="md" style={{ width: "90%" }} />

            <OptionLabel
              leftText="Example Sale Price"
              rightText={data?.attributes.traditionalSalePrice}
              type="dollar"
              color="orange"
            />

            <OptionLabel
              leftText="Selling Commission (Service Fee)"
              rightText={data?.attributes.traditionalCommission}
              type="percent"
            />

            <OptionLabel
              leftText="Tax and Title Fees"
              rightText={data?.attributes.traditionalTaxTitleFee}
              type="percent"
            />

            <OptionLabel
              leftText="Concessions & Repairs"
              rightText="Based on buyer inspection and circumstances"
            />

            <OptionLabel
              leftText="Est. Net Proceeds"
              rightText={data?.attributes.traditionalNetProceeds}
              type="dollar"
              large
              color="green"
            />
          </Stack>
        </Grid.Col>
      </Grid>
    </Group>
  )
}
