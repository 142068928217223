import { LoaderDefault } from "components/common";
import { Group, Image, Space, Stack, Text } from "@mantine/core";
import { DefaultPageWrapper } from "layouts";
import {
  GetOffer,
  MarketingPageCta,
  MarketingPageFooter,
  MarketingPageHeader,
  MarketingPageSellingOptions,
  marketingPageHooks
} from "features/marketing-pages";
import { MarketingPageSeamlessHomeSelling } from "features/marketing-pages/components/marketing-page.seamless-home-selling";
import { FaqsPreview } from "features/faqs";
import { COLORS, LARGE_SCREEN_WIDTH } from "settings";
import { useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import ReactGA from "react-ga4";

export function MarketingPage() {
  const largeScreen = useMediaQuery(LARGE_SCREEN_WIDTH);

  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactName, setContactName] = useState("");

  //eslint-disable-next-line
  const { isLoading, data } = marketingPageHooks.useGetBySlug(
    new URL(window.location.href).pathname.split("/")[1],
    {
      populate: ["faqs"]
    }
  );

  if (isLoading) return <LoaderDefault text="Loading..." />;

  if (!data) {
    window.location.href = "https://www.gkpropertieslv.com/";
  }

  ReactGA.initialize("G-0MB35MP9JB");

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: window.location.pathname.split("/")[1] || "home"
  });

  return (
    <Stack
      spacing={0}
      justify="center"
      align="center"
      style={{ backgroundColor: "white" }}
    >
      <MarketingPageHeader
        data={data}
        contactEmail={contactEmail}
        contactPhone={contactPhone}
        contactName={contactName}
        onSubmit={(name: string, phone: string, email: string) => {
          setContactName(name);
          setContactEmail(email);
          setContactPhone(phone);
        }}
      />

      <Image
        fit="cover"
        src={data?.attributes.heroImageUrl}
        height={largeScreen ? 650 : "auto"}
      />

      <GetOffer
        data={data}
        overlap
        centered
        size="xl"
        contactEmail={contactEmail}
        contactPhone={contactPhone}
        contactName={contactName}
        onSubmit={(name: string, phone: string, email: string) => {
          setContactName(name);
          setContactEmail(email);
          setContactPhone(phone);
          ReactGA.event("request_offer", {
            category: "Conversion",
            action: "Click",
            label: `Request Offer ${data?.attributes.slug.toUpperCase()}}`
          });
        }}
      />

      <Space h="xl" />

      <DefaultPageWrapper>
        <Group position="center">
          <Text
            weight={"bold"}
            size={"xl"}
            color={COLORS.red}
            align="center"
          >
            Home Selling Options: Cash Offer or Maximize Profits - You Decide!
          </Text>
        </Group>

        <Space h="sm" />

        <Group position="center">
          <MarketingPageSellingOptions data={data} />
        </Group>

        <Space h="xl" />

        <Group style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Text
            align="center"
            size={"sm"}
            color={COLORS.red}
          >
            These numbers are examples provided for educational purposes. Offer
            eligibility and price will vary and, when listing, market conditions
            and results will vary. Estimated net proceeds shown do not yet
            account for customer's mortgage payoff, if any.
          </Text>
        </Group>

        <Space h="xl" />
        <Space h="xl" />

        <MarketingPageSeamlessHomeSelling data={data} />

        <GetOffer
          data={data}
          overlap
          centered
          contactEmail={contactEmail}
          contactPhone={contactPhone}
          contactName={contactName}
          onSubmit={(name: string, phone: string, email: string) => {
            setContactName(name);
            setContactEmail(email);
            setContactPhone(phone);
          }}
        />

        <Space h="xl" />
        <Space h="xl" />

        <FaqsPreview
          data={data?.attributes.faqs?.data}
          marketingPage={data}
        />

        <Space h="xl" />
        <Space h="xl" />

        <MarketingPageCta
          data={data}
          contactEmail={contactEmail}
          contactPhone={contactPhone}
          contactName={contactName}
          onSubmit={(name: string, phone: string, email: string) => {
            setContactName(name);
            setContactEmail(email);
            setContactPhone(phone);
          }}
        />

        <Space h="xl" />
        <MarketingPageFooter data={data} />
      </DefaultPageWrapper>
    </Stack>
  );
}
