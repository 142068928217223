import { FaqDisplay } from "features/faqs"

import { LoaderDefault } from "components/common"
import { Space, Stack, Title } from "@mantine/core"
import { DefaultPageWrapper } from "layouts"
import {
  MarketingPageFooter,
  MarketingPageHeader,
  marketingPageHooks,
} from "features/marketing-pages"

export function Faqs() {
  //eslint-disable-next-line
  const { isLoading, data } = marketingPageHooks.useGetBySlug(
    new URL(window.location.href).pathname.split("/")[1],
    {
      populate: ["faqs"],
    }
  )

  if (isLoading) return <LoaderDefault text="Loading..." />
  if (!data) {
    window.location.href = "https://www.gkpropertieslv.com/"
  }

  return (
    <Stack
      spacing={0}
      justify="center"
      align="center"
      style={{ backgroundColor: "white" }}
    >
      <MarketingPageHeader data={data} />

      <Space h="xl" />

      <DefaultPageWrapper>
        <Title mb={16}>Frequently asked questions</Title>
        <Stack>
          {data?.attributes.faqs?.data?.map((f, i) => {
            return <FaqDisplay key={i} data={f} />
          })}
        </Stack>
        <Space h="xl" />
        <MarketingPageFooter data={data} />
      </DefaultPageWrapper>
    </Stack>
  )
}
