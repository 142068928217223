import { IFlatUser, IUser } from "features/users"
import { ROLES } from "settings"

export class RolesUtils {
  static isAppAdmin(user?: IUser | IFlatUser | null) {
    return RolesUtils.isRoles([ROLES.APPLICATION_ADMINISTRATOR], user)
  }

  static isRoles(roles: ROLES[], user?: IUser | IFlatUser | null) {
    if (!user) {
      return false
    } else {
      const currentRole = user?.role?.name as ROLES
      return roles.indexOf(currentRole) !== -1
    }
  }
}
