import {ModelHooks} from "services/strapi";
import { faqApi } from "../services/faq.strapi.api";
import { IFaq } from "../types/faq.types";

class FaqHooks extends ModelHooks<IFaq> {
    /** Add more functions or properties here that are not described in the Base Strapi
     *  Model Hooks Service.
     **/

}

export const faqHooks = new FaqHooks(faqApi);
