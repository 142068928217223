export enum RouteSegments {
  Dashboard = "dashboard",
  Settings = "settings",
  Users = "users",

  Auth = "auth",
  Login = "login",
  Register = "register",
  ForgotPassword = "forgot-password",
  AcceptInvitation = "accept-invitation",
  ResetPassword = "reset-password",

  Wildcard = "*",
  Root = "/",

  /* Generated Route Segments */
  Faqs = "faqs",
}

export enum RouteParams {
  UserId = ":userId",

  /* Generated Route Params */
  FaqId = ":faqId",
}
