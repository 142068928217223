import {ModelHooks} from "services/strapi";
import { marketingPageApi } from "../services/marketing-page.strapi.api";
import { IMarketingPage } from "../types/marketing-page.types";

class MarketingPageHooks extends ModelHooks<IMarketingPage> {
    /** Add more functions or properties here that are not described in the Base Strapi
     *  Model Hooks Service.
     **/

}

export const marketingPageHooks = new MarketingPageHooks(marketingPageApi);
