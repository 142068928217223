import { Grid, List, Paper, Stack, Text, Title } from "@mantine/core"
import {
  GetOffer,
  IMarketingPage,
  TrackYourHomeValue,
} from "features/marketing-pages"
import { IStrapiEntity } from "services/strapi"
import { COLORS } from "settings"

interface Props {
  data?: IStrapiEntity<IMarketingPage>
  contactEmail?: string
  contactPhone?: string
  contactName?: string
  onSubmit?: (name: string, phone: string, email: string) => void
}

export function MarketingPageCta({
  data,
  contactEmail,
  contactPhone,
  contactName,
  onSubmit,
}: Props) {
  if (!data) return null

  return (
    <Grid>
      <Grid.Col sm={6}>
        <Paper
          style={{
            backgroundColor: COLORS.red,
            borderRadius: 8,
            border: "1px solid black",
          }}
          p={24}
        >
          <Stack>
            <Title color="white">Unlock Exclusive Real Estate Insights!</Title>
            <Text color="white" weight={600}>
              Not quite interested in selling just yet? No problem join our
              community to stay in touch and gain access to:
            </Text>
            <List withPadding>
              <List.Item>
                <Text color="white" weight={600}>
                  A monthly valuation of your home
                </Text>
              </List.Item>
              <List.Item>
                <Text color="white" weight={600}>
                  Tips for maximizing your home's value
                </Text>
              </List.Item>
              <List.Item>
                <Text color="white" weight={600}>
                  Market trends and expert insights
                </Text>
              </List.Item>
              <List.Item>
                <Text color="white" weight={600}>
                  Exclusive offers and local real estate news
                </Text>
              </List.Item>
            </List>
            <Stack spacing={8}>
              <Text color="white" weight={600} size={"lg"}>
                Enter your email below
              </Text>
              <TrackYourHomeValue
                data={data}
                contactEmail={contactEmail}
                contactPhone={contactPhone}
                contactName={contactName}
                onSubmit={(name: string, phone: string, email: string) => {
                  if (onSubmit) onSubmit(name, phone, email)
                }}
              />
            </Stack>
          </Stack>
        </Paper>
      </Grid.Col>
      <Grid.Col sm={6}>
        <Stack spacing={38}>
          <Paper
            style={{
              backgroundColor: COLORS.gray,
              borderRadius: 8,
            }}
          >
            <Stack spacing={0} p={16} h={120}>
              <Text color={COLORS.red} size={"xl"} weight={600}>
                Call us
              </Text>
              <Text weight={600} size={"xl"} underline>
                <a href={`tel:+1${data?.attributes.phoneNumber}`}>
                  {data?.attributes.phoneNumber}
                </a>
              </Text>
            </Stack>
          </Paper>
          <Paper
            style={{
              backgroundColor: COLORS.gray,
              borderRadius: 8,
            }}
          >
            <Stack spacing={0} p={16} h={120}>
              <Text color={COLORS.red} size={"xl"} weight={600}>
                Email us
              </Text>
              <Text weight={600} size={"xl"} underline>
                <a
                  href={`mailto:${data.attributes.email}?subject=Request An Offer!`}
                >
                  {data.attributes.email}
                </a>
              </Text>
            </Stack>
          </Paper>
          <Paper
            style={{
              backgroundColor: COLORS.gray,
              borderRadius: 8,
            }}
          >
            <Stack spacing={0} p={16} h={120}>
              <Text color={COLORS.red} size={"xl"} weight={600}>
                Request an offer
              </Text>
              <GetOffer
                data={data}
                size="lg"
                contactEmail={contactEmail}
                contactPhone={contactPhone}
                contactName={contactName}
                onSubmit={(name: string, phone: string, email: string) => {
                  if (onSubmit) onSubmit(name, phone, email)
                }}
              />
            </Stack>
          </Paper>
        </Stack>
      </Grid.Col>
    </Grid>
  )
}
