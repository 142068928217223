import {ModelRoutes, ModelApiService} from "services/strapi";
import {IUserDatum} from "features/user-data";

class UserDatumApiSingleton extends ModelApiService<IUserDatum> {

    serialize = {
        update(userDatumId:number, values: any) {
            const formData = new FormData();
            const {
                avatar,
                ...data
            } = values;
            if (avatar && avatar.length === 1) {
                formData.append(`files.avatar`, avatar[0], avatar[0].name);
            }
            formData.append('data', JSON.stringify(data));
            return {
                id: userDatumId,
                values: formData
            }
        }
    }
}

export const userDatumApi = new UserDatumApiSingleton(ModelRoutes.UserData)


