import { Button, Group, Modal, Paper, Text, TextInput } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IMarketingPage, LeadForm } from "features/marketing-pages"
import { useState } from "react"
import { usePlacesWidget } from "react-google-autocomplete"
import { IAddressComponent } from "services/google"
import { IStrapiEntity } from "services/strapi"
import { COLORS, LARGE_SCREEN_WIDTH } from "settings"

interface Props {
  data?: IStrapiEntity<IMarketingPage>
  overlap?: boolean
  centered?: boolean
  size?: "xs" | "sm" | "md" | "lg" | "xl"
  contactEmail?: string
  contactPhone?: string
  contactName?: string
  onSubmit?: (name: string, phone: string, email: string) => void
}

export function GetOffer({
  data,
  overlap,
  centered,
  size = "xl",
  contactEmail,
  contactPhone,
  contactName,
  onSubmit,
}: Props) {
  const largeScreen = useMediaQuery(LARGE_SCREEN_WIDTH)

  const [openLeadForm, setOpenLeadForm] = useState(false)
  const [value, setValue] = useState<IAddressComponent[] | null>(null)
  if (!data) return null

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyDH62710jQrOW0GqUIi1zm84cTWweaxqPU",
    onPlaceSelected: (place) => {
      setValue(place.address_components)
    },
    options: {
      types: ["address"],
      fields: ["address_components"],
      componentRestrictions: { country: "US" },
    },
  }) as any

  return (
    <Group
      position={centered ? "center" : "left"}
      style={{
        width: "100%",
        marginTop: overlap ? (largeScreen ? "-25px" : "-15px") : "inherit",
      }}
      noWrap
    >
      <TextInput
        placeholder="Enter your home address"
        size={largeScreen ? size : "xs"}
        style={{ width: "60%" }}
        id={"get-offer-input"}
        ref={ref}
        pl={8}
      />
      <Button
        size={largeScreen ? size : "xs"}
        onClick={() => {
          setOpenLeadForm(true)
        }}
        mr={8}
      >
        Get Offer
      </Button>
      <Modal
        opened={openLeadForm}
        onClose={() => setOpenLeadForm(false)}
        centered
        closeOnClickOutside={false}
        size={1000}
        title={
          <Text color={COLORS.red} size={"xl"} weight={600}>
            Request Your Cash Offer
          </Text>
        }
      >
        <Paper p={16}>
          <LeadForm
            data={data}
            contactEmail={contactEmail}
            contactPhone={contactPhone}
            contactName={contactName}
            onSubmit={(name: string, phone: string, email: string) => {
              if (onSubmit) onSubmit(name, phone, email)
              setOpenLeadForm(false)
            }}
            addressComponents={value}
          />
        </Paper>
      </Modal>
    </Group>
  )
}
