import { Button, Modal, Paper, Text } from "@mantine/core"
import { IMarketingPage } from "features/marketing-pages"
import { useState } from "react"
import { IStrapiEntity } from "services/strapi"
import { COLORS, LARGE_SCREEN_WIDTH } from "settings"
import { LeadForm } from "./lead-form"
import { useMediaQuery } from "@mantine/hooks"

interface Props {
  data?: IStrapiEntity<IMarketingPage>
  contactEmail?: string
  contactPhone?: string
  contactName?: string
  onSubmit?: (name: string, phone: string, email: string) => void
}

export function GetStarted({
  data,
  contactEmail,
  contactPhone,
  contactName,
  onSubmit,
}: Props) {
  const largeScreen = useMediaQuery(LARGE_SCREEN_WIDTH)

  const [openLeadForm, setOpenLeadForm] = useState(false)

  if (!data) return null

  return (
    <>
      <Button
        size={largeScreen ? "lg" : "md"}
        onClick={() => {
          setOpenLeadForm(true)
        }}
      >
        Get Started
      </Button>
      <Modal
        opened={openLeadForm}
        onClose={() => setOpenLeadForm(false)}
        centered
        closeOnClickOutside={false}
        size={1000}
        title={
          <Text color={COLORS.red} size={"xl"} weight={600}>
            Request Your Cash Offer
          </Text>
        }
      >
        <Paper p={16}>
          <LeadForm
            data={data}
            contactEmail={contactEmail}
            contactPhone={contactPhone}
            contactName={contactName}
            onSubmit={(name: string, phone: string, email: string) => {
              if (onSubmit) onSubmit(name, phone, email)
              setOpenLeadForm(false)
            }}
          />
        </Paper>
      </Modal>
    </>
  )
}
