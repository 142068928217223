import { StrapiAuthApi } from "./auth.api"
import { strapi } from "lib/strapi-api"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { JWT_LS_KEY, REMEMBER_LOGIN_LS_KEY } from "settings"
import {
  ILoginValues,
  IStrapiForgotPasswordValues,
  IStrapiResetPasswordValues,
} from "./types"
import { showNotification } from "@mantine/notifications"
import { ArrowRight, CircleCheck } from "tabler-icons-react"
import { Button, Stack, Text } from "@mantine/core"
import { RoutesResolver } from "routes"

export class StrapiAuthHooks {
  strapiAuthApi
  meQuery

  constructor(strapiAuthApi: StrapiAuthApi<any>, meQuery: any) {
    this.strapiAuthApi = strapiAuthApi
    this.meQuery = meQuery
  }

  get meQueryKey() {
    return ["me", this.meQuery]
  }

  private me = async (authToken: string) => {
    const res = await this.strapiAuthApi.me(authToken, this.meQuery)
    if (res) {
      strapi.defaults.headers.common["Authorization"] = `Bearer ${authToken}`
      return res
    } else {
      return undefined
    }
  }

  useLogin = () => {
    const queryClient = useQueryClient()
    return useMutation(
      async (values: ILoginValues) => {
        if (values.remember && values.identifier) {
          localStorage.setItem(REMEMBER_LOGIN_LS_KEY, values.identifier)
        }
        const authResponse = await this.strapiAuthApi.login(values)
        localStorage.setItem(JWT_LS_KEY, authResponse.jwt)
        return await this.me(authResponse.jwt)
      },
      {
        onSuccess: (data) => {
          queryClient.setQueryData(this.meQueryKey, data)
        },
      }
    )
  }

  useLogout = () => {
    return () => {
      strapi.defaults.headers.common["Authorization"] = ""
      localStorage.removeItem(JWT_LS_KEY)
      window.location.href = "/"
    }
  }

  useForgotPassword = () => {
    return useMutation(
      async (values: IStrapiForgotPasswordValues) => {
        return await this.strapiAuthApi.forgotPassword(values)
      },
      {
        onSuccess: () => {
          showNotification({
            title: "Success!",
            message:
              "If your email exists, you will receive a password reset email.",
            color: "green",
          })
        },
      }
    )
  }

  useResetPassword = () => {
    return useMutation(
      async (values: IStrapiResetPasswordValues) => {
        return await this.strapiAuthApi.resetPassword(values)
      },
      {
        onSuccess: () => {
          const routesResolver = new RoutesResolver()
          showNotification({
            title: "Success!",
            autoClose: false,
            message: (
              <Stack>
                <Text>
                  Your password has been successfully reset. Click here to
                  navigate to the login page.
                </Text>
                <Button
                  rightIcon={<ArrowRight size={18} />}
                  onClick={() => window.location.replace(routesResolver.login)}
                >
                  Back to login page
                </Button>
              </Stack>
            ),
            color: "green",
            icon: <CircleCheck size={18} />,
          })
        },
      }
    )
  }

  useMe = () => {
    const authToken = localStorage.getItem(JWT_LS_KEY)
    return useQuery(
      this.meQueryKey,
      async () => {
        if (!authToken) {
          return null
        } else {
          return await this.me(authToken)
        }
      },
      // If you set a stale time of 0, anytime a component renders under another component using
      // this function, you'll end up making duped calls. Since we only use this call at app
      // load and sparingly otherwise, we can set the stale time to 1 second.
      { staleTime: 1000 }
    )
  }
}
