import {Stack} from "@mantine/core";
import {DEFAULT_MAX_CONTENT_WIDTH} from "settings";

interface Props {
    children:React.ReactNode,
    maxWidth?:number,
}

export function DefaultPageWrapper({children, maxWidth}:Props){
    return (
        <Stack p={24} align="center">
            <Stack sx={{maxWidth: maxWidth || DEFAULT_MAX_CONTENT_WIDTH, width: "100%"}} spacing={0}>
                {children}
            </Stack>
        </Stack>
    )
}
