import { Stack, Text } from "@mantine/core"
import { DefaultPageWrapper } from "layouts"

export function Test() {
  return (
    <Stack spacing={0}>
      <DefaultPageWrapper>
        <Text>Test page for GK Web</Text>
      </DefaultPageWrapper>
    </Stack>
  )
}
