import {Select, SelectProps} from "@mantine/core"
import { CONTIGUOUS_STATES } from "utils"

export function StateSelect({...rest}: Omit<SelectProps, 'data'>) {

    return (
        <Select
            searchable
            clearable
            filter={(value, item) =>
                item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
                item.value?.toLowerCase().includes(value.toLowerCase().trim())
            }
            data={CONTIGUOUS_STATES.map(s => {
                return {
                    value: s.abbreviation,
                    label: s.name
                }
            })}
            {...rest}/>
    )
}